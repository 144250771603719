import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'


const ItDistrict = () => {
  const [cursor, setCursor] = useState('pointer')

  useEffect(() => {
    const elem = document.querySelector('.itdistrict')
    elem.style.opacity = 0
    window.requestAnimationFrame(function () {
      elem.style.transition = '5500ms'
      elem.style.transitionDelay = 'opacity 2500ms'
      elem.style.transitionTimingFunction = 'ease-in'
      elem.style.opacity = 1
    })
  }, [])

  const handleClick = () => {
    setCursor('none')
  }

    return (
      <div className="itdistrict">
        <div className="vertically-centered">
          <p>
            <span className="hidden">
              I, chattering animal, spit purpoil—smiley to feel stupid Screaming
              the secrets of Myth-sized crust of{' '}
            </span>
            <Link
              id="thunders"
              className="link"
              to="/By-itself-time"
              onClick={handleClick}
            >
              thunders in the index.
            </Link>
          </p>
          <p>
            <span className="hidden">
              I, chattering animal, spit purpoil—smiley to feel stupid Screaming
              the secrets of Myth-sized{' '}
            </span>
            <Link
              id="crust"
              className="link"
              to="/Parched-spirits"
              onClick={handleClick}
            >
              crust of thinghood.
            </Link>
          </p>
          <p>
            <span className="hidden">
              I, chattering animal, spit purpoil—smiley to feel stupid Screaming
              the secrets of.{' '}
            </span>
            <Link
              id="myth"
              className="link"
              to="/The-body-delighted"
              onClick={handleClick}
            >
              Myth-sized
            </Link>
          </p>
          <p>
            <span className="hidden">
              I, chattering animal, spit purpoil—smiley to feel stupid{' '}
            </span>
            <Link
              id="screaming"
              className="link"
              to="I-am-the-inheritor"
              onClick={handleClick}
            >
              Screaming the secrets of immobile life,
            </Link>
          </p>
          <p>
            <span className="hidden">
              I, chattering animal, spit purpoil—smiley to{'..'}
            </span>
            <Link
              id="feel-stupid"
              className="link"
              to="/Prestigious-ghosts"
              onClick={handleClick}
            >
              feel stupid
            </Link>
            <span className="hidden">Screaming the secrets of </span>
            <Link
              id="cuckolded"
              className="link"
              to="/Prestigious-ghosts"
              onClick={handleClick}
            >
              cuckolded singers
            </Link>
          </p>
          <p>
            <span className="hidden">I, chattering animal, spit purpoil </span>
            <Link
              id="smiley"
              className="link"
              to="/a-continuous-flow-of-introductions"
              onClick={handleClick}
            >
              —smiley to know the taste{' '}
            </Link>
            <span className="hidden">
              eaming the secrets of cuckolded singers
            </span>
            <Link
              id="cannot-explain"
              className="link"
              to="/Pictorial-preoccupations"
              onClick={handleClick}
            >
              cannot explain
            </Link>
          </p>
          <p>
            <span className="hidden">I, chattering animal, </span>
            <Link
              id="spit-purpoil"
              className="link"
              to="scud-angel"
              onClick={handleClick}
            >
              spit purpoil
            </Link>
            <span className="hidden">
              —smiley to feel stupid Screaming the secrets of cuckolded
              singersss
            </span>
            <Link
              id="otherother-surrogates"
              className="link"
              to="/Pictorial-preoccupations"
              onClick={handleClick}
            >
              other surrogates
            </Link>
          </p>
          <p>
            <Link
              id="chattering"
              className={`link ${cursor}`}
              to="/The-crowd-presses-me"
              onClick={handleClick}
            >
              I, chattering, animal,
            </Link>
            <span className="hidden">
              spit purpoil—smiley to feel stupid Screaming the secrets of
              cuckolded singersss
            </span>
            <Link
              id="for-celibacy"
              className="link"
              to="/Pictorial-preoccupations"
              onClick={handleClick}
            >
              for celibacy.
            </Link>
          </p>
          <p>
            <span className="hidden">I, chattering animal, </span>
            <Link
              id="laugh-at"
              className="link"
              to="/I-laugh-at-my-fierce-guest"
              onClick={handleClick}
            >
              laugh at my fierce guest
            </Link>
          </p>
          <p>
            <span className="hidden">I, chattering animal, laugh at my..</span>
            <Link
              id="slow-thighs"
              className="link"
              to="/Regent-of-Interregnum"
              onClick={handleClick}
            >
              slow thighs almost dance
            </Link>
          </p>
          <p>
            <span className="hidden">
              I, chattering animal, laugh at my fierce guest..
            </span>
            <Link
              id="would-prefer"
              className="link"
              to="/Hunters-behind-clouds"
              onClick={handleClick}
            >
              would prefer to kill
            </Link>
          </p>
          <p>
            <span className="hidden">
              I, chattering animal,
            </span>
            <Link
              id="could-live-like-this-forever"
              className="link"
              to="/Dear-Doctor"
              onClick={handleClick}
            >
              could live like this forever.
            </Link>
          </p>
          <p>
            <span className="hidden">
              I, chattering animal, laugh at my slow thighs would..{' '}
            </span>
            <Link
              id="appease"
              className="link"
              to="/You-must-have-regard"
              onClick={handleClick}
            >
              appease all of your needs at once;
            </Link>
          </p>
        </div>
      </div>
    )
}

export default ItDistrict
