import React from 'react';
import { Link } from 'react-router-dom';

const DearDoctor = ({ cursorVisibility }) => {
  return (
    <Link id={cursorVisibility} className="Dear-Doctor link none" to="/">
      <div>
        <p>
          Dear Doctor,
        </p>
        <br />
        <p>
          Be the first to know that I could live like this forever. No more will I go to the theatre, the billiard room, to
        </p>
        <p>
          centers of services, aspirations, and infinite commerce, where the music that gives no choice, touching and
        </p>
        <p>
          clawing, starts again, and where ghosts of loved ones, irrevocably lost in that ticklish business of securing
        </p>
        <p>
          what they can, seize my hat and my coat. No-I will stay here and make gentle again that clown in the corner,
        </p>
        <p>
          and fix these signs, and (maybe) I will leave my handprint on the ceiling, as I would so like to do. Oh, Doctor.
        </p>
        <p>
          I think you know all this. For you are self-confident in the wisdom that recognizes a secret compartment in
        </p>
        <p>
          things, reserved for the initiated, and holds the key. So do send my way a gust of teaching, and fix that sign
        </p>
        <p>
          (the situation on the cross), and scatter smiles into these isolated rooms, where only they are only true.
        </p>
        <br />
        <p>Yours faithfully,</p>
        <p>The Student</p>
      </div>
    </Link>
  );
};

export default DearDoctor;
